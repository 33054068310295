import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Company from "@/types/Company";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface CompanyInfo {
  company: Company;
}

@Module
export default class CompanyModule extends VuexModule implements CompanyInfo {
  company = {} as Company;

  @Mutation
  [Mutations.SET_COMPANY](company) {
    this.company = company;
  }

  @Action
  [Actions.PROVINCE_LIST]() {
    return ApiService.get("province")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }


}
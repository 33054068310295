import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Order from "@/types/Order";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface DeliveryInfo {
  delivery: any;
  deliveryLine: any;
}

@Module
export default class DeliveryModule extends VuexModule implements DeliveryInfo {
  delivery = {} as any;
  deliveryLine= {} as any;
  @Mutation
  [Mutations.SET_DELIVERY](delivery) {
    this.delivery = delivery;
  }

  @Mutation
  [Mutations.SET_DELIVERY_LINE](deliveryLine) {
    this.deliveryLine = deliveryLine;
  }


  @Action
  [Actions.DELIVERY_LIST](page) {
    return ApiService.get("Order/" + page + "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ORDER, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.DELIVERY_LINE_LIST](id) {
    return ApiService.get("Delivery/line/" + id + "")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELIVERY_LINE, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_DELIVERY](args) {
    return ApiService.post("delivery", args).then(({ data }) => {
     
      if (!data.success) {
        return data;
      } else {        
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_DELIVERY](args) {
    return ApiService.put("Delivery", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_DELIVERY](id) {
    return ApiService.delete("Delivery/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

}

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/employee/add",
        name: "employee",
        component: () => import("@/views/apps/employee/add/Index.vue"),
      },
      {
        path: "/definitions/difference-reasons",
        name: "difference-reasons",
        component: () =>
          import("@/views/apps/definitions/difference-reasons/Index.vue"),
      },
      {
        path:"authorization",
        name:"authorization",
        component: () => import("@/views/apps/authorization/Index.vue"),
        children: [
         
        ]
      },
      {
        path:"screens",
        name:"screens",
        component: () => import("@/views/apps/screens/Index.vue"),
        children: [
         
        ]
      },
      {
        path:"screens/add",
        name:"screens-add",
        component: () => import("@/views/apps/screens/Add.vue"),
      },
      {
        path: "/screens/update/:id",
        name: "screens-update",
        component: () => import("@/views/apps/screens/Update.vue"),
      },
        {
          path: "/definitions/customer",
          name: "customer",
          component: () => import("@/views/apps/definitions/customers/Index.vue"),
        },
      {
        path: "/definitions/department",
        name: "department",
        component: () =>
          import("@/views/apps/definitions/departments/Index.vue"),
      },
      {
        path: "/definitions/season",
        name: "season",
        component: () => import("@/views/apps/definitions/seasons/Index.vue"),
      },
      {
        path: "/definitions/supplier",
        name: "supplier",
        component: () => import("@/views/apps/definitions/suppliers/Index.vue"),
      },
      {
        path: "/definitions/size",
        name: "size",
        component: () =>
          import("@/views/apps/definitions/size/size-set-add/Index.vue"),
      },
      {
        path: "/definitions/account",
        name: "definition-account",
        component: () => import("@/views/apps/definitions/accounts/Index.vue"),
      },
      {
        path: "/order",
        name: "order-list",
        component: () => import("@/views/apps/orders/Index.vue"),
      },
      {
        path: "/order/add",
        name: "order-add",
        component: () => import("@/views/apps/orders/Add.vue"),
      },
      {
        path: "/order/detail/:id",
        name: "order-detail",
        component: () => import("@/views/apps/orders/Detail.vue"),
      },
      {
        path: "/report/performance",
        name: "report-performance",
        component: () =>
          import("@/views/apps/reports/performance-report/Index.vue"),
      },
      {
        path: "/report/difference",
        name: "report-difference",
        component: () =>
          import("@/views/apps/reports/difference-report/Index.vue"),
      },
      {
        path: "/report/order",
        name: "report-order",
        component: () => import("@/views/apps/reports/order-report/Index.vue"),
      },
      {
        path: "/report/deleted-order",
        name: "report-deleted-order",
        component: () =>
          import("@/views/apps/reports/deleted-order-report/Index.vue"),
      },
      {
        path: "/report/shipping",
        name: "report-shipping",
        component: () =>
          import("@/views/apps/reports/shipping-report/Index.vue"),
      },
      {
        path: "/management/transaction",
        name: "transaction-log",
        component: () => import("@/views/apps/management/Index.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/settings",
        name: "setting",
        component: () => import("@/views/settings/Index.vue"),
        children: [
          {
            path: "/company-settings",
            name: "Şirket Ayarları",
            component: () =>
              import("@/views/settings/company-setting/Index.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH).then((f) => {
    if (f.status == 401) {
      router.push("/sign-in");
    }
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;

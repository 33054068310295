const ID_TOKEN_KEY = "access_token" as string;
const user = "user" as any;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};
export const saveUser = (user: any): void => {
  window.localStorage.setItem("user", JSON.stringify(user));
}
export const getUser = (): any | null => {
  return window.localStorage.getItem("user");
};
/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken,saveUser,getUser };

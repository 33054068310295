import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import Paginator from "primevue/paginator";
import InputMask from "primevue/inputmask";
import ToastService from "primevue/toastservice";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Fieldset from "primevue/fieldset";
import Panel from "primevue/panel";
import Divider from "primevue/divider";
import MultiSelect from "primevue/multiselect";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';  
import Row from 'primevue/row';  
import ConfirmPopup from "primevue/confirmpopup";
import Avatar from 'primevue/avatar';
import Toast from 'primevue/toast';
import OrderList from 'primevue/orderlist';
import Timeline from 'primevue/timeline';
import Chart from 'primevue/chart';
//imports for app initialization

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import "@/core/plugins/prismjs";
import "bootstrap";

import "primeicons/primeicons.css";

const app = createApp(App);
app.use(PrimeVue, {
  locale: {
    startsWith: "Başlangıç",
    contains: "İçinde",
    notContains: "İçermez",
    endsWith: "Bitiş",
    equals: "Eşit",
    notEquals: "Eşit Değil",
    noFilter: "No Filter",
    lt: "Less than",
    lte: "Less than or equal to",
    gt: "Greater than",
    gte: "Greater than or equal to",
    dateIs: "Date is",
    dateIsNot: "Date is not",
    dateBefore: "Date is before",
    dateAfter: "Date is after",
    clear: "Temizle",
    apply: "Uygula",
    matchAll: "Tümünü Eşleştir",
    matchAny: "Herhangi Birini Eşleştir",
    addRule: "Kural Ekle",
    removeRule: "Kural Sil",
    accept: "Yes",
    reject: "No",
    choose: "Choose",
    upload: "Upload",
    cancel: "Cancel",
    dayNames: [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ],
    dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    dayNamesMin: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "Oca",
      "Şub",
      "Mar",
      "Nis",
      "May",
      "Haz",
      "Tem",
      "Ağu",
      "Eyl",
      "Eki",
      "Kas",
      "Ara",
    ],
    today: "Today",
    weekHeader: "Wk",
    firstDayOfWeek: 1,
    dateFormat: "mm/dd/yy",
    weak: "Zayıf",
    medium: "Orta",
    strong: "Güçlü",
    passwordPrompt: "Şifre giriniz",
    emptyFilterMessage: "No results found",
    emptyMessage: "No available options",
  },
});
app.use(store);
app.use(router);
app.use(ElementPlus,{} as any);
app.use(ToastService);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.component("Button", Button);
app.component("Card", Card);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("Calendar", Calendar);
app.component("Fieldset", Fieldset);
app.component("Divider", Divider);
app.component("Panel", Panel);
app.component("InputMask", InputMask);
app.component("InputNumber", InputNumber);
app.component("Dropdown", Dropdown);
app.component("MultiSelect", MultiSelect);
app.component("Dialog", Dialog);
app.component("FileUpload", FileUpload);
app.component("CheckBox", Checkbox);
app.component("Password", Password);
app.component("Paginator", Paginator);
app.component("Image", Image);
app.component("InputMask ", InputMask);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("ConfirmPopup", ConfirmPopup);
app.component("Toast",Toast);
app.component("Avatar",Avatar);
app.component("OrderList",OrderList);
app.component("Timeline",Timeline)
app.component("Chart",Chart);
app.mount("#app");

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Season from "@/types/Season";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SeasonInfo {
  season: Season;
}

@Module
export default class SeasonModule extends VuexModule implements SeasonInfo {
  season = {} as Season;

  @Mutation
  [Mutations.SET_SEASON](season) {
    this.season = season;
  }

  @Action
  [Actions.SEASON_LIST]() {
    return ApiService.get("Season")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SEASON, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_SEASON](args) {
    return ApiService.post("Season", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.UPDATE_SEASON](args) {
    return ApiService.put("Season", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.REMOVE_SEASON](id) {
    return ApiService.delete("Season/" + id + "")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}

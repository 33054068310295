import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Screen from "@/types/Screen";
export interface ScreenInfo {
  screen: Screen;
  action: any;
  screenWithoutChildren: Screen;
}
@Module
export default class ScreenModule extends VuexModule implements ScreenInfo {
  screen = {} as Screen;
  action = {} as any;
  screenWithoutChildren = {} as Screen;
  @Mutation
  [Mutations.SET_SCREEN](screen) {
    this.screen = screen;
  }

  @Mutation
  [Mutations.SET_ACTION](action) {
    this.action = action;
  }

  @Mutation
  [Mutations.SET_SCREEN_WITHOUT_CHILDREN](screenWithoutChildren) {
    this.screenWithoutChildren = screenWithoutChildren;
  }

  @Action
  [Actions.SCREEN_LIST]() {
    return ApiService.get("menu")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCREEN, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
   @Action
  [Actions.SCREEN_LIST_WITHOUT_CHILDREN]() {
    return ApiService.get("screen/without-children")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCREEN_WITHOUT_CHILDREN, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ACTION_LIST]() {
    return ApiService.get("menu/action")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ACTION, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SCREEN_GET_BY_ID](id) {
    return ApiService.get("screen/" + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCREEN, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ADD_SCREEN](args) {
    return ApiService.post("screen", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.ADD_SCREEN_ACTION](args) {
    return ApiService.post("screen/action", args).then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  @Action
  [Actions.DELETE_SCREEN](id) {
    return ApiService.delete("screen/"+id+"").then(({ data }) => {
      if (!data.success) {
        return data;
      } else {
        //this.context.commit(Mutations.SET_SIZE_SET, data);
        return data;
      }
    });
  }

  //   @Action
  //   [Actions.UPDATE_CUSTOMER](args) {
  //     return ApiService.put("Customer", args).then(({ data }) => {
  //       if (!data.success) {
  //         return data;
  //       } else {
  //         //this.context.commit(Mutations.SET_SIZE_SET, data);
  //         return data;
  //       }
  //     });
  //   }

  //   @Action
  //   [Actions.REMOVE_CUSTOMER](id) {
  //     return ApiService.delete("Customer/" + id + "")
  //       .then(({ data }) => {
  //         return data;
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         return response;
  //       });
  //   }
}

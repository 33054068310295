import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Currency from "@/types/Currency";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CurrencyInfo {
  currency: Currency;
}

@Module
export default class CurrencyModule extends VuexModule implements CurrencyInfo {
  currency = {} as Currency;

  @Mutation
  [Mutations.SET_CURRENCY](currency) {
    this.currency = currency;
  }

  @Action
  [Actions.CURRENCY_LIST]() {
    return ApiService.get("currency")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CURRENCY, data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

}
